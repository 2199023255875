import React, { useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import classNames from "classnames"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import { Section, Container } from "../components/Containers"
import { MarkdownViewer } from "../components/Markdown"

const DonatePage = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { hero, heading, blurb, credit, check } = post

  const [activeButton, setActiveButton] = useState("")

  const selectButton = type => {
    if (type === activeButton) {
      setActiveButton("")
    } else {
      setActiveButton(type)
    }
  }

  return (
    <Layout className="donate-page">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
        pathname={location.pathname}
      />

      <NuvoImage publicId={hero.image} wrapperClassName="is-hidden-mobile" />
      <NuvoImage
        publicId={hero.imageMobile}
        wrapperClassName="is-hidden-tablet"
      />

      <Section>
        <Container>
          <h1 className="has-text-centered">{heading}</h1>
          <MarkdownViewer markdown={blurb} />

          <div className="donate-page__payments-grid">
            {/* <PaymentMethod
              selectButton={selectButton}
              activeButton={activeButton}
              type="credit"
              buttonText={credit.buttonText}
              links={credit.links}
            /> */}
            <div className="payment-methods--credit">
              <a
                href="https://buy.stripe.com/cN22bl81T59Bb5K28j"
                target="_blank"
                title="Made a donation by credit card"
                className="standard-button">
                Credit Card
              </a>
            </div>
            <PaymentMethod
              selectButton={selectButton}
              activeButton={activeButton}
              type="check"
              buttonText={check.buttonText}
              content={check.content}
            />
          </div>
        </Container>
      </Section>
    </Layout>
  )
}

const PaymentMethod = ({
  type,
  buttonText,
  links,
  content,
  selectButton,
  activeButton
}) => {
  const credit = type === "credit"
  const check = type === "check"
  // const [isOpen, setIsOpen] = useState(false)
  const containerClasses = classNames("payment-methods", {
    "payment-methods--credit": credit,
    "payment-methods--check": check
  })
  const buttonClasses = classNames({
    "standard-button": true,
    reqular: activeButton != type,
    "outline-red": activeButton === type
  })

  return (
    <div className={containerClasses}>
      <a className={buttonClasses} onClick={() => selectButton(type)}>
        {buttonText}
      </a>

      <div className="payment-medthods__details">
        {credit && activeButton === type && (
          <div className="payment-methods__links">
            <p className="bold">Select amount: </p>
            {links.map(link => (
              <a
                key={link.link}
                href={link.link}
                target="_blank"
                title={`Donate ${link.name}`}>
                {link.name}
              </a>
            ))}
          </div>
        )}
        {check && activeButton === type && (
          <MarkdownViewer markdown={content} />
        )}
      </div>
    </div>
  )
}

DonatePage.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const donatePageQuery = graphql`
  query donateQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        hero {
          image
          imageMobile
        }
        heading
        blurb
        credit {
          buttonText
          links {
            link
            name
          }
        }
        check {
          buttonText
          content
        }
      }
    }
  }
`

export default DonatePage
